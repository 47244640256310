<template>
  <div class='row'>
    <div class='col-lg-12'>
      <div class='card card-top card-top-primary'>
        <div class='card-header d-flex justify-content-between flex-lg-row flex-column align-items-lg-center'>
          <vb-headers-card-header :data="{ title: 'Filtre&nbsp;Seçiniz' }" />

          <div class='d-flex flex-lg-row flex-column ml-2'>
            <a-select :default-value='premiumFilterState' class='mr-lg-2 mb-lg-0 mb-2 w-100' style='min-width: 230px' @change='premiumFilterStateChange'>
              <a-select-option value='ALL'>
                Tüm Hesaplar
              </a-select-option>
              <a-select-option value='ONLY_PREMIUMS'>
                Premium Hesaplar
              </a-select-option>
              <a-select-option value='ONLY_NON_PREMIUMS'>
                Premium Olmayan Hesaplar
              </a-select-option>
            </a-select>
            <a-input-search v-model:value='searchText' placeholder='Arama Yapın...' @search='loadCustomers' enter-button='Ara' />
          </div>
        </div>

        <div class='card-header d-flex justify-content-between flex-lg-row flex-column align-items-lg-center'>
          <vb-headers-card-header :data="{ title: 'Kullanıcı&nbsp;Seçiniz' }" />

          <div class='d-flex flex-lg-row flex-column ml-2'>
            <a-select :default-value='filterType' class='mr-lg-2 mb-lg-0 mb-2 w-100' style='min-width: 200px;' @change='filterTypeChange'>
              <a-select-option value='SELECTED_USERS'>
                Sadece Seçilen Kullanıcılar
              </a-select-option>
              <a-select-option value='FILTERED_USERS'>
                Filtre Sonucundaki Herkes
              </a-select-option>
            </a-select>

            <a-button v-if='filterType === "SELECTED_USERS"' class='btn btn-warning mr-lg-2 mb-lg-0 mb-2' @click='selectAllCustomers'>Tümünü Seç</a-button>
            <a-button v-if='filterType === "SELECTED_USERS"' class='btn btn-danger mr-lg-2 mb-lg-0 mb-2' :disabled='selectedCustomers.length < 1'
                      @click='() => {selectedCustomers = []}'>
              Seçimleri Kaldır
              <span
                v-if='selectedCustomers.length > 0'>{{ `&nbsp;(${selectedCustomers.length})` }}
                </span>
            </a-button>

            <a-popover trigger='click' placement='bottomLeft'>
              <div @click.prevent>
                <button type='button' class='btn btn-light dropdown-toggle dropdown-toggle-noarrow'>
                  <i class='fe fe-eye' />
                </button>
              </div>
              <template #content>
                <div class='d-flex flex-column' v-for='(item, index) in columns' :key='(index + 9).toString(36) + index'>
                  <a-checkbox v-if='item.title !== "ID" && item.title !== "Yönet"' class='py-1' v-model:checked='item.visible'
                              @change='() => localStore.set("users.row.visible." + item.dataIndex, item.visible)'>{{ item.title }}
                  </a-checkbox>
                </div>
              </template>
            </a-popover>

          </div>
        </div>

        <div class='card-header d-flex justify-content-between flex-lg-row flex-column align-items-lg-center'>
          <vb-headers-card-header :data="{ title: 'Premium&nbsp;Ver' }" />

          <div class='d-flex flex-lg-row flex-column ml-2'>
            <a-select :loading='loadingSubPlans' placeholder='Üyelik Paketi Seçiniz' class='mr-lg-2 mb-lg-0 mb-2 w-100' style='min-width: 230px'
                      :disabled='filterType === "SELECTED_USERS" && selectedCustomers.length < 1' v-model:value='selectedSubscriptionPlan'>
              <template v-for='(item, index) in subscriptionPlans' :key='(index + 9).toString(36) + index'>
                <a-select-option :value='item.id'>
                  {{ `${item.durationAsMonths} Aylık` }}
                </a-select-option>
              </template>
            </a-select>
            <a-popconfirm
              :title='addToPremiumConfirmationText'
              ok-text='Evet'
              cancel-text='Hayır'
              @confirm='addPremiumToUsers'
              :disabled='(filterType === "SELECTED_USERS" && selectedCustomers.length < 1) || selectedSubscriptionPlan === null'
            >
              <a-button class='btn btn-success' :disabled='(filterType === "SELECTED_USERS" && selectedCustomers.length < 1) || selectedSubscriptionPlan === null'>
                {{ filterType === 'SELECTED_USERS' ? 'Seçili Hesaplara Premium Ver' : 'Filtre Sonucundaki Hesaplara Premium Ver' }}
              </a-button>
            </a-popconfirm>
          </div>
        </div>


        <div class='card-body'>
          <!-- TABLO -->
          <div class='table-responsive text-nowrap'>
            <a-table
              :columns='columns.filter(x => x.visible)'
              :data-source='customers'
              :locale='{emptyText: "Henüz Kullanıcı Yok"}'
              :row-key='(record) => record.id'
              :loading='loading'
              :pagination='false'
              :custom-row='customRow'
            >
              <template #id='{ record }'>
                <a-checkbox v-if='filterType === "SELECTED_USERS"' :checked='!!selectedCustomers.find(x => x.id === record.id)'></a-checkbox>
                <span class='font-weight-bold ml-2'>{{ record.id }}</span>
              </template>
              <template #avatar='{ record }'>
                <div class='vb__utils__avatar' style='width: 2.5rem' @click.stop>
                  <a-image
                    :src='record.avatar.avatar4X'
                    alt='User'
                    fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                  />
                </div>
              </template>
              <template #fullName='{ record }'>
                <span>{{ record.fullName }}</span>
              </template>
              <template #email='{ record }'>
                <span>{{ record.email }}</span>
              </template>
              <template #premium='{ record }'>
                <span>{{ getCustomerActivePremiumType(record.id) }}</span>
              </template>
              <template #accountCreatedAt='{ record }'>
                <span :class='!record.accountCreatedAt ? "font-italic text-gray-3" : ""'>{{ record.accountCreatedAt ? moment(record.accountCreatedAt).format('LL') : 'Belirtilmemiş'
                  }}</span>
              </template>
              <template #birthDate='{ record }'>
                <span :class='!record.birthDate ? "font-italic text-gray-3" : ""'>{{ record.birthDate ? moment(record.birthDate).format('LL') : 'Belirtilmemiş' }}</span>
              </template>
              <template #weight='{ record }'>
                <span :class='!record.weight ? "font-italic text-gray-3" : ""'>{{ record.weight ? `${record.weight} KG` : 'Belirtilmemiş' }}</span>
              </template>
              <template #height='{ record }'>
                <span :class='!record.height ? "font-italic text-gray-3" : ""'>{{ record.height ? `${record.height} CM` : 'Belirtilmemiş' }}</span>
              </template>
              <template #referencedPersonalTrainer='{ record }'>
                <span
                  :class='record.referencedPersonalTrainer === null ? "font-italic text-gray-3" : ""'>{{ record.referencedPersonalTrainer !== null ? record.referencedPersonalTrainer.name + ' ' + record.referencedPersonalTrainer.surname : 'Belirtilmemiş'
                  }}</span>
              </template>
              <template #fitnessTargets='{ record }'>
                <span class='font-italic text-gray-3' v-if='record.fitnessTargets.length === 0'>Belirtilmemiş</span>
                <template v-for='(item) in record.fitnessTargets' :key='item.id + "fitnessTargets"'>
                  <span>{{ item.name + ', ' }}</span>
                </template>
              </template>
              <template #heardFrom='{ record }'>
                <span class='font-italic text-gray-3' v-if='record.heardFrom.length === 0'>Belirtilmemiş</span>
                <template v-for='(item) in record.heardFrom' :key='item.id + "heardFrom"'>
                  <span>{{ item.name + ', ' }}</span>
                </template>
              </template>
              <template #operation='{record}'>
                <a-button
                  class='btn btn-primary mr-1'
                  :loading='modalLoading === record.id'
                  shape='circle'
                  @click.stop='() => showModal(record.id)'
                >
                  <i class='fe fe-settings' v-if='modalLoading !== record.id' />
                </a-button>
                <a-popconfirm
                  title='Kullanıcıyı Silmek İstediğinize Emin Misiniz?'
                  ok-text='Evet'
                  cancel-text='Hayır'
                  @confirm='confirmDelete(record.customerMetadataId)'
                  @click.stop
                >
                  <a-button shape='circle' class='btn btn-danger' @click.stop>
                    <i class='fe fe-trash' />
                  </a-button>
                </a-popconfirm>
              </template>
            </a-table>
            <div class='clearfix my-3'>
              <a-pagination
                class='float-right'
                @change='loadCustomers'
                v-model:page-size='pagination.recordsPerPage'
                :default-page-size='pagination.recordsPerPage'
                v-model:current='pagination.currentPage'
                :default-current='pagination.currentPage'
                :total='pagination.totalRecords'
                :show-total='total => `Toplam ${total} kullanıcı`'
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal v-model:visible='visible' width='90%' centered title='Kullanıcı Bilgilerini Düzenle'>
      <template #footer>
        <a-button key='back' @click='() => {visible = false}'>Kapat</a-button>
      </template>

      <div class='row'>

        <div class='col-lg-6 col-md-12'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='card card-top card-top-primary'>
                <div class='card-header'>
                  <vb-headers-card-header :data="{ title: 'Kullanıcı Bilgileri' }" />
                </div>
                <div class='card-body'>
                  <a-form
                    label-align='left'
                    layout='vertical'
                  >

                    <a-form-item label='Adı Soyadı' name='username'>
                      <a-input disabled v-model:value='name' />
                    </a-form-item>

                    <a-form-item label='Mail Adresi' name='mail' @keypress='validateEmail(customerMail)'>
                      <a-input v-model:value='customerMail' />
                      <a-tag color='error' v-if='customerMail !== "" & !validateEmail(customerMail)' class='mt-2'>
                        <template #icon>
                          <i class='fe fe-alert-circle' />
                        </template>
                        Geçerli Bir Mail Adresi Giriniz
                      </a-tag>

                      <a-button :disabled='!validateEditCustomerMail' v-if='showMailSaveButton && validateEditCustomerMail' @click='updateCustomerMail'
                                class='btn btn-success mt-2'>
                        Güncelle
                      </a-button>
                    </a-form-item>


                    <a-form-item label='Şifre' name='password'>
                      <a-input-password v-model:value='password' />
                      <a-tag color='error' v-if='password !== "" && (password.length < 6 || password.length > 64)'
                             class='mt-2'>
                        <template #icon>
                          <i class='fe fe-alert-circle' />
                        </template>
                        Şifre Minimum 6, Maximum 64 Karakter olmalıdır
                      </a-tag>
                      <a-button :disabled='!validateEditCustomerPassword' v-if='showPasswordSaveButton && validateEditCustomerPassword' @click='updateCustomerPassword'
                                class='btn btn-success mt-2'>
                        Güncelle
                      </a-button>
                    </a-form-item>

                    <div class='d-flex justify-content-between'>
                      <div class='w-100 mr-2'>
                        <a-form-item label='Premium Durumu' name='premium'>
                          <span class='border p-2 rounded font-weight-bold'>{{ showPremiumStatusOfCustomer }}</span>
                        </a-form-item>

                        <a-popconfirm
                          title='Premium Durumunu Değiştirmek İstediğinizden Emin misiniz?'
                          ok-text='Evet'
                          cancel-text='Hayır'
                          @confirm='updateCustomerPremiumStatus'
                        >
                          <a-button class='btn' :class='customerPremiumStatus ? "btn-danger" : "btn-primary"'>
                            {{ customerPremiumStatus ? 'Premiumu Kaldır' : 'Süresiz Premium Yap' }}
                          </a-button>
                        </a-popconfirm>
                      </div>

                      <a-form-item label='Premium Başlangıç Tarihi' name='premium' class='w-100'>
                        <span
                          class='border p-2 rounded'
                          :class='currentCustomer.currentPremiumStartedAt ? "font-weight-bold" : "font-italic text-gray-3"'>{{ currentCustomer.currentPremiumStartedAt ? moment(currentCustomer.currentPremiumStartedAt).format('LL') : 'Belirtilmemiş'
                          }}</span>
                      </a-form-item>

                      <a-form-item label='Premium Bitiş Tarihi' name='premium' class='w-100'>
                        <span
                          class='border p-2 rounded'
                          :class='currentCustomer.currentPremiumEndsAt ? "font-weight-bold" : "font-italic text-gray-3"'>{{ currentCustomer.currentPremiumEndsAt ? moment(currentCustomer.currentPremiumEndsAt).format('LL') : 'Belirtilmemiş'
                          }}</span>
                      </a-form-item>
                    </div>
                  </a-form>

                </div>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='card card-top card-top-primary'>
                <div class='card-header'>
                  <vb-headers-card-header :data="{ title: 'Hesap İşlemleri' }" />
                </div>
                <div class='card-body d-flex justify-content-between'>
                  <a-popconfirm
                    title='Kullanıcıyı Silmek İstediğinize Emin Misiniz?'
                    ok-text='Evet'
                    cancel-text='Hayır'
                    @confirm='confirmDelete(currentCustomer.customerMetadataId)'
                  >
                    <a-button class='btn btn-danger'>Hesabı Kalıcı Olarak Sil</a-button>
                  </a-popconfirm>

                  <span
                    class='border p-1 rounded font-weight-bold'>Kayıt Tarihi: {{ currentCustomer.accountCreatedAt ? moment(currentCustomer.accountCreatedAt).format('LL') : moment().format('LL')
                    }}</span>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='col-lg-6 col-md-12'>
          <div class='card card-top card-top-primary'>
            <div class='card-header'>
              <vb-headers-card-header :data="{ title: 'Kullanıcı Aktiviteleri' }" />
            </div>
            <div class='card-body'>
              <div class='table-responsive text-nowrap overflow-auto card-max-height'>
                <a-table
                  :pagination='false'
                  :columns='columnsActivity'
                  :data-source='currentCustomerActivities'
                  :row-key='(record) => record.id + "activity"'
                  :loading='loading'
                  :locale='{emptyText: "Henüz Aktivite Yok"}'
                >
                  <template #id='{ record }'>
                    <span class='font-weight-bold'>{{ record.id }}</span>
                  </template>
                  <template #activityType='{ record }'>
                    <span>{{ writeActivityType(record.activityType) }}</span>
                  </template>
                  <template #happenedAt='{ record }'>
                    <span>{{ moment(record.happenedAt).format('LL, HH:mm') }}</span>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </a-modal>

  </div>
</template>

<script>
import { ref } from 'vue'
import { notification } from 'ant-design-vue'
import { validateEmail } from '../../utils/email'
import { writeActivityType } from '@/utils/text-helper'
import { CustomerAdministration, SubscriptionPlanAdministration } from '../../services/api-service'
import { default as localStore } from 'store'

import moment from 'moment'
import trLocale from 'moment/locale/tr'

moment.updateLocale('tr', trLocale)

export default {
  name: 'Kullanicilar',
  data() {
    const visible = ref(false)

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        slots: { customRender: 'id' },
        visible: true,
      },
      {
        title: 'Avatar',
        class: 'w-auto',
        dataIndex: 'avatar',
        slots: { customRender: 'avatar' },
        visible: localStore.get('users.row.visible.avatar') ?? true,
      },
      {
        title: 'Kullanıcı Bilgisi',
        dataIndex: 'fullName',
        slots: { customRender: 'fullName' },
        visible: localStore.get('users.row.visible.fullName') ?? true,
      },
      {
        title: 'Mail Adresi',
        dataIndex: 'email',
        slots: { customRender: 'email' },
        visible: localStore.get('users.row.visible.email') ?? true,
      },
      {
        title: 'Premium',
        dataIndex: 'id',
        className: 'text-center',
        slots: { customRender: 'premium' },
        visible: localStore.get('users.row.visible.premium') ?? true,
      },
      {
        title: 'Kayıt Tarihi',
        dataIndex: 'accountCreatedAt',
        slots: { customRender: 'accountCreatedAt' },
        visible: localStore.get('users.row.visible.accountCreatedAt') ?? true,
      },
      {
        title: 'Doğum Tarihi',
        dataIndex: 'birthDate',
        slots: { customRender: 'birthDate' },
        visible: localStore.get('users.row.visible.birthDate') ?? true,
      },
      {
        title: 'Kilo',
        dataIndex: 'weight',
        slots: { customRender: 'weight' },
        visible: localStore.get('users.row.visible.weight') ?? true,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        slots: { customRender: 'height' },
        visible: localStore.get('users.row.visible.height') ?? true,
      },
      {
        title: 'Kişisel Antrenör',
        dataIndex: 'referencedPersonalTrainer',
        slots: { customRender: 'referencedPersonalTrainer' },
        visible: localStore.get('users.row.visible.referencedPersonalTrainer') ?? true,
      },
      {
        title: 'Hedefleri',
        dataIndex: 'fitnessTargets',
        slots: { customRender: 'fitnessTargets' },
        visible: localStore.get('users.row.visible.fitnessTargets') ?? true,
      },
      {
        title: 'Bizi Nereden Duydunuz?',
        dataIndex: 'heardFrom',
        class: 'w-100',
        slots: { customRender: 'heardFrom' },
        visible: localStore.get('users.row.visible.heardFrom') ?? true,
      },
      {
        title: 'Yönet',
        class: 'text-right',
        slots: { customRender: 'operation' },
        visible: true,
      },
    ]

    const columnsActivity = [
      {
        title: 'ID',
        dataIndex: 'id',
        slots: { customRender: 'id' },
      },
      {
        title: 'Aktivite Türü',
        dataIndex: 'activityType',
        slots: { customRender: 'activityType' },
      },
      {
        title: 'Saati',
        dataIndex: 'happenedAt',
        slots: { customRender: 'happenedAt' },
      },
    ]

    const showModal = async (id) => {
      this.modalLoading = id
      this.currentCustomer = this.customers.find(x => x.id === id)

      if (this.currentCustomer) {
        this.name = this.currentCustomer.fullName
        this.customerMail = this.currentCustomer.email
        this.customerMailFromServer = this.currentCustomer.email
        this.customerPremiumStatus = this.currentCustomer.premium
      }

      try {
        const { data } = await CustomerAdministration.listCustomerActivitiesForAdministration(this.currentCustomer.customerMetadataId)
        if (data.activities) {
          this.currentCustomerActivities = data.activities
        }
      } catch {
        this.modalLoading = null
        return false
      }

      this.modalLoading = null
      visible.value = true
    }

    return {
      validateEmail,
      writeActivityType,
      visible,
      showModal,
      columns,
      columnsActivity,
      modalLoading: null,
      loading: '',
      customers: [],
      selectedCustomers: [],
      currentCustomer: {},
      currentCustomerActivities: [],
      orderByJoinCountOfBroadcasts: false,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
      customerMetaData: '',
      customerPremiumStatus: '',
      customerMailFromServer: '',
      customerMail: '',
      password: '',
      name: '',
      searchText: '',
      // 'ALL' | 'ONLY_PREMIUMS' | 'ONLY_NON_PREMIUMS'
      premiumFilterState: 'ALL',
      // "FILTERED_USERS" | "SELECTED_USERS"
      filterType: 'FILTERED_USERS',
      subscriptionPlans: [],
      loadingSubPlans: false,
      selectedSubscriptionPlan: null,
      localStore,
    }
  },
  computed: {
    showPremiumStatusOfCustomer() {
      if (this.currentCustomer.activePremiumType === 'APPLE') {
        return 'Premium (Apple)'
      } else if (this.currentCustomer.activePremiumType === 'IYZICO') {
        return 'Premium (Iyzico)'
      } else if (this.currentCustomer.activePremiumType === 'FREE_PREMIUM_WITH_DURATION') {
        return 'Ücretsiz Premium (Süreli)'
      } else if (this.currentCustomer.activePremiumType === 'FREE_PREMIUM_WITHOUT_DURATION') {
        return 'Ücretsiz Premium (Süresiz)'
      }

      return this.customerPremiumStatus === true ? 'Premium' : 'Premium Değil'
    },
    showMailSaveButton() {
      return this.customerMailFromServer !== this.customerMail
    },
    showPasswordSaveButton() {
      return this.password.length > 1
    },
    validateEditCustomerMail() {
      return (
        this.validateEmail(this.customerMail) &&
        this.customerMail !== ''
      )
    },
    validateEditCustomerPassword() {
      return this.password.length >= 6 && this.password.length <= 64
    },
    addToPremiumConfirmationText() {
      if (this.filterType === 'SELECTED_USERS') {
        if (this.selectedCustomers.length > 1) {
          return `${this.selectedCustomers.length} üyeyi premium olarak eklemek istediğinize emin misiniz?`
        } else if (this.selectedCustomers.length === 1) {
          return `${this.selectedCustomers[0].fullName} üyesini premium olarak eklemek istediğinize emin misiniz?`
        } else {
          return 'Herkesi premium olarak eklemek istediğinize emin misiniz?'
        }
      }
      if (this.filterType === 'FILTERED_USERS') {
        if (this.premiumFilterState === 'ALL') {
          return 'Arama sonucunda çıkan tüm hesapları premium olarak eklemek istediğinize emin misiniz? Bu işlem çok uzun sürebilir.'
        }
        if (this.premiumFilterState === 'ONLY_PREMIUMS') {
          return 'Arama sonucunda çıkan Sadece premium hesapları premium olarak eklemek istediğinize emin misiniz? Bu işlem hatalara sebep olabilir.'
        }
        if (this.premiumFilterState === 'ONLY_NON_PREMIUMS') {
          return 'Arama sonucunda çıkan Sadece premium olmayan hesapları premium olarak eklemek istediğinize emin misiniz?'
        }
      }

      return 'Emin misiniz ?'
    },
  },
  created: function() {
    this.moment = moment
  },
  mounted() {
    this.loadCustomers()
    this.loadSubscriptionPlans()
  },
  methods: {
    getCustomerActivePremiumType(customerId) {
      const customer = this.customers.find(x => x.id === customerId)
      if (customer) {
        if (customer.activePremiumType === 'APPLE') {
          return 'Premium (Apple)'
        } else if (customer.activePremiumType === 'IYZICO') {
          return 'Premium (Iyzico)'
        } else if (customer.activePremiumType === 'FREE_PREMIUM_WITH_DURATION') {
          return 'Ücretsiz Premium (Süreli)'
        } else if (customer.activePremiumType === 'FREE_PREMIUM_WITHOUT_DURATION') {
          return 'Ücretsiz Premium (Süresiz)'
        } else {
          return customer.premium ? 'Premium' : 'Premium Değil'
        }
      }

      return 'Premium Değil'
    },
    async loadCustomers() {
      this.loading = true
      try {
        const { data } = await CustomerAdministration.listCustomersForAdministration(this.orderByJoinCountOfBroadcasts, this.pagination.currentPage, this.premiumFilterState, this.searchText)
        this.customers = data.customers
        this.pagination = data.pagination
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadSubscriptionPlans() {
      this.loadingSubPlans = true

      try {
        const { data } = await SubscriptionPlanAdministration.listSubscriptionPlansForAdministration()
        this.subscriptionPlans = data.subscriptionPlans
      } catch (e) {
      }

      this.loadingSubPlans = false
    },
    async updateCustomerMail() {
      try {
        await CustomerAdministration.updateEmailOfCustomer({ newEmail: this.customerMail, id: this.currentCustomer.id })
        this.customerMailFromServer = this.customerMail

        this.currentCustomer.email = this.customerMail

        const indexOldElement = this.customers.findIndex(({ id }) => id === this.currentCustomer.id)
        this.customers = Object.assign([...this.customers], { [indexOldElement]: this.currentCustomer })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Mail Adresi başarıyla güncellendi.',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateCustomerPassword() {
      try {
        await CustomerAdministration.updatePasswordOfCustomer({
          newPassword: this.password,
          id: this.currentCustomer.id,
        })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Şifre Başarıyla Güncellendi!',
        })
        this.password = ''
      } catch (e) {
        console.log(e)
      }
    },
    async updateCustomerPremiumStatus() {
      try {
        await CustomerAdministration.updatePremiumStatusOfCustomer({
          newPremiumStatus: !this.customerPremiumStatus,
          id: this.currentCustomer.id,
        })

        this.customerPremiumStatus = !this.customerPremiumStatus
        this.currentCustomer.premium = this.customerPremiumStatus
        this.currentCustomer.activePremiumType = this.customerPremiumStatus ? 'FREE_PREMIUM_WITHOUT_DURATION' : 'NOT_PREMIUM'
        this.currentCustomer.currentPremiumStartedAt = this.customerPremiumStatus ? moment().format('YYYY-MM-DD HH:mm:ss') : null
        this.currentCustomer.currentPremiumEndsAt = null

        const indexOldElement = this.customers.findIndex(({ id }) => id === this.currentCustomer.id)
        this.customers = Object.assign([...this.customers], { [indexOldElement]: this.currentCustomer })

        notification.success({
          message: `${this.currentCustomer.fullName} ${this.customerPremiumStatus ? 'Artık Premium' : 'Artık Premium Değil'}`,
        })

      } catch {
      }
    },
    async confirmDelete(id) {
      try {
        await CustomerAdministration.deleteCustomer({ id })

        this.customers = this.customers.filter((r) => {
          return r.customerMetadataId !== id
        })

        this.visible = false
        this.currentCustomer = {}
        this.currentCustomerActivities = []
        this.name = ''
        this.customerMail = ''
        this.customerMailFromServer = ''
        this.customerPremiumStatus = ''

        notification.success({
          message: 'Kullanıcı Silindi',
        })

      } catch {
      }
    },
    async addPremiumToUsers() {
      try {
        await CustomerAdministration.addPremiumToUsers({
          addTo: this.filterType,
          users: this.selectedCustomers.map(({ customerMetadataId }) => customerMetadataId),
          filterPremiumType: this.premiumFilterState,
          filterSearchText: this.searchText,
          subscriptionPackageId: this.selectedSubscriptionPlan,
        })

        const currSubscriptionPlan = this.subscriptionPlans.find(({ id }) => id === this.selectedSubscriptionPlan)

        this.customers.forEach((r) => {
          if (this.selectedCustomers.includes(r)) {
            r.premium = true
            r.activePremiumType = 'FREE_PREMIUM_WITH_DURATION'
            r.currentPremiumStartedAt = moment().format('YYYY-MM-DD HH:mm:ss')
            r.currentPremiumEndsAt = moment().add(currSubscriptionPlan?.durationAsMonths ?? 1, 'month').format('YYYY-MM-DD HH:mm:ss')
          }

          return r
        })

        this.selectedSubscriptionPlan = null

        notification.success({
          message: 'Kullanıcılara Premium Eklendi',
        })
      } catch {
      }
    },
    clickCustomer(customer) {
      const findCustomer = this.selectedCustomers.find(x => x.id === customer.id)
      if (typeof findCustomer !== 'undefined') {
        this.deleteCustomer(customer)
      } else {
        this.addCustomer(customer)
      }
    },
    addCustomer(customer) {
      if (!this.selectedCustomers.includes(customer)) this.selectedCustomers.push(customer)
    },
    deleteCustomer(customer) {
      this.selectedCustomers = this.selectedCustomers.filter(x => x.id !== customer.id)
    },
    selectAllCustomers() {
      this.selectedCustomers = [...this.selectedCustomers, ...this.customers.filter(x => !this.selectedCustomers.includes(x))]
    },
    premiumFilterStateChange(value) {
      if (this.premiumFilterState !== value) {
        this.premiumFilterState = value
      }
    },
    filterTypeChange(value) {
      if (this.filterType !== value) {
        this.selectedCustomers = []
        this.filterType = value
      }
    },
    customRow(record) {
      return {
        onClick: () => {
          if (this.filterType === 'SELECTED_USERS') {
            this.clickCustomer(record)
          }
        },
      }
    },
  },
}
</script>

<style scoped>
.card-max-height {
  height: 540px;
}
</style>
